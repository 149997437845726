const getLaunchOptions = (() => {
  try {
    const args = new URLSearchParams(window.location.search);
    const _userId = args.get('userId') || '';
    const _token = args.get('token') || '';
    const launchMode = args.get('launchMode') || '';
    const ___ = {
      token: _token,
      userId: _userId,
      launchMode,
    };
    return () => {
      return ___;
    };
  } catch (e) {
    return () => {
      return {
        token: '',
        userId: '',
        launchMode: '',
      };
    };
  }
})();

export { getLaunchOptions };
