import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { getGraphQLClient } from 'src/graphql';
import * as zoid from 'zoid/dist/zoid.frameworks';
import * as Logger from '@crimson-education/browser-logger';
import { getLaunchOptions } from './utils/launchOptions';
import {
  ApolloProvider,
  // NormalizedCacheObject,
  //  ApolloClient
} from '@apollo/client';

import App, { ApolloClientContext } from './App';
import ErrorBoundary from './components/error/ErrorBoundary';
import { config } from './config';
// import { QUERY_USER_INFO } from '@crimson-education/core-shared-graphql/user';

// interface IUserInfo {
//   user: {
//     userId: string;
//     email: string;
//     firstName: string;
//     fullName: string;
//     lastName: string;
//     profileImageUrl?: string;
//     roles?: { roleId: string; isPrimary: boolean }[];
//     tenant?: {
//       id: string;
//       level: number;
//       name: string;
//     };
//   };
// }

interface IMetaMessage {
  type: 'META';
  isInit?: boolean;
  payload: Record<string, unknown>;
}

interface IInvokeReturnMessage {
  type: 'INVOKE_RETURN';
  id: string;
  returnValue: unknown;
}

interface IInvokeErrorMessage {
  type: 'INVOKE_ERROR';
  id: string;
  errorMessage: string;
}

interface IEventMessage {
  type: 'EVENT';
  payload: {
    eventName: string;
    eventData: unknown;
  };
}

type IMessage = IMetaMessage | IInvokeReturnMessage | IInvokeErrorMessage | IEventMessage;

type AnyFunc = (...args: unknown[]) => unknown;

const {
  // userId,
  // token,
  launchMode,
} = getLaunchOptions();

if (launchMode !== 'neo') {
  zoid.create({
    // The html tag used to render my component
    tag: 'resource-component',
    // The url that will be loaded in the iframe or popup, when someone includes my component on their page
    url: config.domain,

    props: {
      token: {
        type: 'string',
        isRequired: false,
      },
      featureSwitches: {
        type: 'object',
        isRequired: false,
      },
      userId: {
        type: 'string',
        isRequired: true,
      },
      authorize: {
        type: 'function',
        isRequired: true,
      },
    },
  });
}

const isLocalhost = window.location.hostname === 'localhost';

Logger.init({
  service: 'resources-frontend',
  environment: config.environment,
  version: config.version,
  defaultMetadata: {
    application: 'resources',
  },
  reporters: {
    log: true,
    datadog: config.datadogApplicationId
      ? {
          applicationId: config.datadogApplicationId,
          clientToken: config.datadogClientToken as string,
          site: config.datadogSite as string,
          proxyUrl: config.datadogTunnelProxyUrl,
          version: config.datadogVersion,
          sampleRate: config.environment === 'production' ? 50 : 0,
          replaySampleRate: config.environment === 'production' ? 50 : 0,
          // Required to use datadog in iframe on https.
          useSecureSessionCookie: !isLocalhost,
          useCrossSiteSessionCookie: !isLocalhost,
          allowedTracingOrigins: [config.resourcesAPIUrl, config.crimsonAppAPIUrl],
          logTransport: config.environment === 'production',
          trackInteractions: true,
          forwardConsoleLogs: false,
        }
      : undefined,
    amplify: config.pinpointAnalyticsAppId
      ? {
          region: config.awsRegion,
          identityPoolId: config.pinpointIdentityPoolId,
          analyticsAppId: config.pinpointAnalyticsAppId,
          proxyUrl: config.pinpointProxyUrl,
          autoTrackPageViews: true,
          autoTrackEvents: true,
          autoTrackSessions: true,
        }
      : undefined,
  },
});

if (config.datadogApplicationId) {
  console.debug('Resources Datadog enabled');
}

const _launch = () => {
  // console.log('debug_launch!');
  ReactDOM.render(
    <React.StrictMode>
      <ErrorBoundary>
        <React.Suspense fallback={null}>
          <Router>
            <ApolloClientContext.Provider
              value={{
                resourceApiClient: getGraphQLClient(new URL('/graphql', config.resourcesAPIUrl).href, window.xprops),
                crimsonAppApiClient: getGraphQLClient(new URL('/graphql', config.crimsonAppAPIUrl).href, window.xprops),
              }}
            >
              <ApolloProvider
                client={getGraphQLClient(new URL('/graphql', config.resourcesAPIUrl).href, window.xprops)}
              >
                <App {...window.xprops} />
              </ApolloProvider>
            </ApolloClientContext.Provider>
          </Router>
        </React.Suspense>
      </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

const patch2Xprops = (src: Record<string, unknown>) => {
  const basic = window?.xprops ?? {};
  window.xprops = Object.assign(basic, src);
  window.dispatchEvent(new CustomEvent('neo_meta', {}));
};

if (launchMode !== 'neo') {
  _launch();
} else {
  const launch = (() => {
    let launched = false;
    return async () => {
      if (launched) {
        return;
      }
      launched = true;
      _launch();
    };
  })();
  // const getUserInfo = (() => {
  //   let client: ApolloClient<NormalizedCacheObject> | null = null;
  //   return async (token:string) => {
  //     if (!client) {
  //       client = getGraphQLClient(new URL('/graphql', config.crimsonAppAPIUrl).href, token);
  //     }
  //     const { data } = await client.query<IUserInfo>({
  //       query: QUERY_USER_INFO,
  //     });
  //     return data.me;
  //   };
  // })();
  patch2Xprops({}); // simply init window.xprops
  const eventCache = {} as Record<string, unknown[]>;
  (() => {
    let cAPPsender: MessageEventSource;
    let counter = 0;
    const resolvers = {} as Record<string, { rej: AnyFunc; res: AnyFunc; startTime: number }>;
    const getId = () => `SC-INVOKE-${counter++}`;
    const eventHandlers = {} as Record<string, AnyFunc>;
    const registerEventEmitter = (eventName: string, cb: AnyFunc) => {
      eventHandlers[eventName] = cb;
      if (eventCache[eventName]?.length > 0) {
        const caches = eventCache[eventName].splice(0);
        caches.forEach((c) => cb(c));
      }
    };
    const unregisterEventEmitter = (eventName: string) => {
      delete eventHandlers[eventName];
    };
    const doRPC = async (functionName: string, args: Array<unknown>) => {
      const startTime = new Date().valueOf();
      const id = getId();
      const Msg = {
        type: 'INVOKE',
        payload: {
          functionName,
          id,
          args,
        },
      };
      const __ = new Promise((res, rej) => {
        cAPPsender.postMessage(Msg, { targetOrigin: '*' });
        resolvers[id] = { res, rej, startTime };
      });
      __.catch((e) => {
        throw new Error(e);
      });
      return __;
    };

    const authorize = async (...args: unknown[]) => {
      return doRPC('authorize', args);
    };

    const pushEvent = async (eventName: string, eventData: string) => {
      const msg = {
        type: 'EVENT',
        payload: {
          eventName,
          eventData,
        },
      };
      return cAPPsender?.postMessage?.(msg, { targetOrigin: '*' });
    };

    const onMessage = (d: { event: string; data: string }) => {
      const { event, data } = d;
      return pushEvent(event, data);
    };

    const builtInMethods = {
      // getBearer,
      // refreshAccessTokens,
      authorize,
    };
    const builtInMethods2 = {
      registerEventEmitter,
      unregisterEventEmitter,
      onMessage,
      // history,
    };
    patch2Xprops(builtInMethods2);

    window.addEventListener('message', (e) => {
      const { data, source } = e as { source: MessageEventSource | null; data: IMessage };
      const { type: messageType } = data;
      if (messageType === 'META') {
        if (!cAPPsender && source && data?.isInit) {
          cAPPsender = source;
        }
      }
      if (cAPPsender !== source) {
        return;
      }
      if (messageType === 'META') {
        patch2Xprops({ ...(data?.payload ?? {}), ...builtInMethods });
        if (data?.isInit) {
          launch();
        }
        return;
      }
      if (messageType === 'INVOKE_ERROR') {
        const { id, errorMessage } = data;
        if (!resolvers[id]) {
          //TODO report it
          throw new Error(`debug_[MESSAGEHANDLE]==> cannot find resolvers for ${id} ${errorMessage}`);
        }
        const { rej } = resolvers[id];
        delete resolvers[id];
        rej(new Error(errorMessage));
        return;
      }
      if (messageType === 'INVOKE_RETURN') {
        const { id, returnValue } = data;
        if (!resolvers[id]) {
          //TODO report it
          throw new Error(`debug_[MESSAGEHANDLE]==> cannot find resolvers for ${id} ${returnValue}`);
        }
        const { res } = resolvers[id];
        delete resolvers[id];
        res(returnValue);
        return;
      }
      if (messageType === 'EVENT') {
        const { eventData, eventName } = data.payload;
        const handler = eventHandlers[eventName];
        if (typeof handler === 'function') {
          eventHandlers[eventName]?.(eventData);
        } else {
          if (!eventCache[eventName]) {
            eventCache[eventName] = [];
          }
          eventCache[eventName].push(eventData);
        }
        return;
      }
    });
  })();
}
